import { Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react'
import FacebookLogin from 'react-facebook-login'
import AddAuthTokenToUser from '../../Common/ApiCalls/MainBackend/AddAuthTokenToUser';
import GetInstaAccounts from '../../Common/ApiCalls/MainBackend/GetInstaAccounts';
import IgAccount from '../../Common/Models/IgAccount';
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification';
import Sleep from '../../Common/Utils/Sleep';
import ContentWrapper from '../../Components/ContentWrapper';
import IgAccountCard from './IgAccountCard';

// Fb App Id
const appId = process.env.REACT_APP_FB_APP_ID;

type FbAccountProps = {
  user: any,
  refreshIg: Function,
  igAccounts: IgAccount[]
}

const FbAccount = ({
  user,
  refreshIg,
  igAccounts
}: FbAccountProps) => {
  const [load, setLoad] = useState(false)
  const [addingToken, setAddingToken] = useState(false)

  /*
  accessToken: "EAA8mGLeAW44BAPKOtw6W4pnMHwoosZAlhoWF7oY7cBJBsZAdbviG8HhfuB8nhMMX49BVHzltTJ3veDgY5IZBbqnjFrDYcermF7aVwFUUGZBGN7ZADzN4d5UKfUTZAYcuGB7JDzMjaEALOTmdlRvcjapdblVWBoeZCRZC5TGpeZBalGlzWvAldYeTbWZBpHRo15uG1njol0kGQu6bDpsa7HTGgZBx5PW2XNRMu0ZD"
  data_access_expiration_time: 1637075031
  email: "kumar.97@iitj.ac.in"
  expiresIn: 6969
  graphDomain: "facebook"
  id: "2041990572621757"
  name: "Aman Kumar"
  picture:
  data:
  height: 50
  is_silhouette: false
  url: "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=2041990572621757&height=50&width=50&ext=1631891032&hash=AeTRMG9FY7-rVfBk7oc"
  width: 50
  signedRequest: "HYLp3ieA2HDBc22RiokHCL3oLiAK0owrfMPi8T-9jf8.eyJ1c2VyX2lkIjoiMjA0MTk5MDU3MjYyMTc1NyIsImNvZGUiOiJBUURJSV9RcDZrUVdnSndoaUQ5OXFBVktOVWtWVDYxWlpTMDlLNVRTbWEydThsSFdiNHFvNUZEa2Q5c1ZoQlZZUHR5Y0RGN0psMUJRWDEtUFloNzM2WENlN3Q0MHdiOXFxMzFydmN1TXVYdjRFWWpSdHhQdDJocWVVRXlTOXBIT0JyV1V4d1hKREdnRzB4eTJNbUFtcHVDVm1GWll3ZHBtVW1sZHJHQ1hYQVBMZHRxemJFelZvbnNWMlJOcW44Y2wwTmVqQzdLNnBib3o3VENUSXg5S2hBbDA0bkxkV3lnZ3FWam1yNGhGN3VIRlRGWm1Jak15ZjZLU3ZZSlZnOFdfVzFYZElycUFOUXJOOTU4M0Vmdk9QeVBfTGd6RWpOdmdqUlpPOTFkdGpzcGxMZmRXWW1TZlJ1NXlvRDJZMmN5M0ViVWYtWXJORFQzU3lLbGpjUWVqVUhCbjVwR1JGeWxLb2JGdU9abXAzaGMwS2ciLCJhbGdvcml0aG0iOiJITUFDLVNIQTI1NiIsImlzc3VlZF9hdCI6MTYyOTI5OTAzMX0"
  userID: "2041990572621757"
  */
  const responseFacebook = (e: any) => {
    const accessToken = e.accessToken
    if (!accessToken) {
      return
    }
    setAddingToken(true)
    AddAuthTokenToUser(accessToken)
      .then(r => {
        setAddingToken(false)
        reload()
        if (r.success) {
          SuccessNotif("Successfully Added.")
        } else {
          BadNotif(r)
        }
      })
  };

  async function reload() {
    setLoad(true)
    console.log('reload')
    await refreshIg()
    setLoad(false)
  }

  useEffect(() => {
  }, [])

  if(appId === undefined) {
    return <div>Invalid App Id</div>
  }

  return (
    <ContentWrapper
      style={{
        minHeight: '80vh'
      }}
    >
      <Row
        justify='center'
        gutter={24}
        key="1"
      >
        <Col>
          <Space direction='vertical'>
            <FacebookLogin
              appId={appId}
              textButton="Connect with facebook"
              fields="name,email,picture"
              scope="
              email,
              public_profile,
              pages_show_list,
              instagram_manage_insights,
              instagram_manage_comments,
              pages_manage_metadata,
              pages_read_engagement,
              instagram_basic,
              instagram_manage_messages,
              pages_messaging
              "
              callback={responseFacebook}
            />
          </Space>
        </Col>
      </Row>
      <Row
        justify='center'
        gutter={24}
        key="2"
      >
        <Col>
          {
            load ? (
              <p>Getting your Info...</p>
            ) : null
          }
        </Col>
      </Row>
      <Row
        justify='center'
        gutter={24}
        key="3"
      >
        <Col>
          {
            addingToken ? (
              <p>Adding your Info to our server</p>
            ) : null
          }
        </Col>
      </Row>
      <Row
        justify='center'
        align='middle'
        gutter={24}
        key="4"
      >
          {
            !load && (
              igAccounts.length >= 1 ? (
                igAccounts.map((igAccount) => (
                  <Col
                    xs={{ span: 20 }}
                    md={{span: 10}}
                  >
                    <IgAccountCard 
                      db_id={igAccount.id}
                      username={igAccount.username}
                      profile_pic_url={igAccount.profile_pic_url}
                      followers={igAccount.follower_count}
                      follwoing={igAccount.following_count}
                      name={igAccount.full_name}
                      media_count={igAccount.media_count}
                      page_name={igAccount.page_name}
                    />
                  </Col>
                ))
              ) : <p>No Ig Business Account Associated</p>
            )
          }
      </Row>
    </ContentWrapper>
  )
}

export default FbAccount