export const ApiConstats = {
  PROD_SERVER_URL: 'https://tech.influencerbit.com/api/fb/v1',
  DEV_SERVER_URL: 'http://localhost:3000/api/fb/v1',
  RUN_PROD_SERVER: true,
  LOCALHOST_SERVER: process.env.NODE_ENV === 'development',
  DEV_DOMAIN: 'localhost',
  PROD_DOMAIN: '.influencerbit.com',
  AUTH_COOKIE_NAME: 'influnecerAppAuthToken',
  AUTH_COOKIE_PATH: "/"
}

export function ServerURL(url = '') {
  const url_to_call = ( ApiConstats.LOCALHOST_SERVER ? ApiConstats.DEV_SERVER_URL : ApiConstats.PROD_SERVER_URL) + url
  return url_to_call
}