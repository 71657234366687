import React, {useState} from 'react'
import ContentWrapper from '../../Components/ContentWrapper'
import {
  Button,
  Divider,
  Space,
  Typography
} from 'antd'
import { Link } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons'
import UpdateMessageModal from "./UpdateMessageModal";

const { Title, Text } = Typography

const helpLinkText = "View help article"

type quickStartStep = {
  title: string,
  helpArticle?: string,
  description?: string,
  helpLink?: string,
  component?: React.ReactNode
}

const OnBoard = ({user}: any) => {
  const [bv, setBV] = useState(false)

  const quickStartSteps: quickStartStep[] = [
    /*   {
        title: "You just finished creating your account"
      }, */
    {
      title: "Connect FB",
      description: 'Connect the IG Business Account you want to get insights of.',
      /*     helpArticle: "https://instagram.com", */
      component: (
          <Link
              to="/connect_fb"
              className="is-size-5"
              style={{
                border: '1px solid black',
                padding: '6px'
              }}
          >
            My Connected Accounts
          </Link>
      )
    },
    {
      title: 'Visualize your Data',
      description: 'Get your Ig Account Insights here and Details about your mentioned medias',
      component: (
          <Link
              to="/data"
              className="is-size-4"
              style={{
                border: '1px solid green',
                padding: '10px'
              }}
          >
            Show my Data
          </Link>
      )
    },
    {
      title: 'Facebook Default Message',
      description: "Change the default message you want to send to your customers",
      component: (
          <Button
              onClick={()=>setBV(!bv)}
              className={"is-size-5"}
              style={{
                border: '1px solid black',
                padding: '10px',
                color: "#485fc7",
                paddingBottom: "40px"
              }}
          >
            Update Message
          </Button>
      )
    }
  ]

  return (
    <ContentWrapper
      style={{
        minHeight: '80vh'
      }}
      addToClassName="has-text-black"
    >
      <UpdateMessageModal
          vis={bv}
          cancel={()=>setBV(false)}
          message={user.message}
      />
      <Title
        level={2}
        className="has-text-black"
      >
        Quick Start Guide
      </Title>
      <Text
        className="has-text-black"
      >
        Welcome to Influencerbit! Please follow these steps to get up and running quickly.
      </Text>
      <Divider />
      <Space
        direction='vertical'
        style={{
          minWidth: "75%"
        }}
      >
        {quickStartSteps.map((guideStep: quickStartStep) => (
          <>
              <Title
                level={3}
                className="has-text-black"
              >
                {guideStep.title}
                {guideStep.helpArticle && (
                  <Link
                    to={guideStep.helpArticle}
                    style={{
                      float: 'right'
                    }}
                  className="is-size-6"
                >
                  {guideStep.helpLink ?? helpLinkText} <RightOutlined />
                </Link>
              )}
            </Title>
            <Text
              className="has-text-black"
            >
              {guideStep.description}
            </Text>
            {guideStep.component}
            <Divider
              className="dark-divider"
            />
          </>
        ))}
      </Space>
    </ContentWrapper>
  )
}

export default OnBoard