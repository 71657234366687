import { Layout } from 'antd'

const {Footer} = Layout

const CustomFooter = () => {
  return (
    <Footer
    style={{
      textAlign: 'center'
    }}
  >
    Influencerbit ©2021 {/* Created with ❤️ by <a target='_blank' href="https://restlessmonks.com">Restlessmonks</a> */}
  </Footer>
  )
}

export default CustomFooter