import { LinkOutlined, SettingOutlined } from '@ant-design/icons'
import { Avatar, Card, Space, Typography } from 'antd'
import React from 'react'

const { Meta } = Card
const {Text, Link} = Typography

type IgACcountProps = {
  username: string,
  db_id: number,
  profile_pic_url: string,
  followers: number,
  follwoing: number,
  name: string,
  media_count: number  ,
  page_name: string
}

const IgAccountCard = ({
  username,
  db_id,
  profile_pic_url,
  followers,
  follwoing,
  name,
  media_count,
    page_name
}: IgACcountProps) => {

  return (
    <Card
      key={db_id}
      style={{
        width: 300,
        marginTop: 16
      }}
/*       actions={[
        <SettingOutlined key="setting" />,
        <LinkOutlined key="insta" />
      ]} */
    >
      <Meta
        avatar={
          <Avatar
            src={profile_pic_url}
          />
        }
        description={name}
        title={username}
      />
      <Space
        direction='vertical'
        className="mt-4"
      >
        <Text>Followers: {followers}</Text>
        <Text>Following: {follwoing}</Text>
        <Text>Posts: {media_count}</Text>
        <Text>Fb Page: {page_name}</Text>
      </Space>
    </Card>
  )
}

export default IgAccountCard