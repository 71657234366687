import React from 'react'
import ContentWrapper from '../../Components/ContentWrapper'
import {
  Typography, Space, Collapse
} from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'

const {Panel} = Collapse
const { Title } = Typography

type faqItem = {
  title: string,
  answer: string
}

const items: faqItem[] = [
  {
    title: 'Which Instagram content will be tracked?',
    answer: "FB allows us to get the tracking information of Instagram Business accounts only as of now."
  },
  {
    title: 'How do you track and show my data?',
    answer: 'We use FB APIs to track and get your data. We can only get this if you have permitted us to do so. You can withdraw permissions anytime.'
  },
  {
    title: 'How can I cancel?',
    answer: 'Just send us an email to support@influencerbit.com and your plan will be cancelled immediately.'
  },
  {
    title: "Are you Live",
    answer: 'We will be live soon as soon as our FB app review is complete. 😊'
  }
]

const FAQ = () => {
  return (
    <ContentWrapper
      style={{
        minHeight: '80vh'
      }}
    >
      <Title
        level={3}
        className="has-text-black"
      >
        FAQs
      </Title>
      <Space
        direction='vertical'
        style={{
          width: '80%'
        }}
      >
        <Collapse
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
        >
          {items.map((item) => (
            <Panel
              header={item.title}
              key={item.title}
              className="site-collapse-custom-panel"
            >
              {item.answer}
            </Panel>
          ))}
        </Collapse>
      </Space>
    </ContentWrapper>
  )
}

export default FAQ